<template>
  <div :class="$style.dustbin" @click="onClick()">
    <a-badge
      :count="count"
      :number-style="{
        backgroundColor: '#1470df',
        color: '#fff',
      }"
      :offset="[16, 12]"
    >
      <i class="ri-delete-bin-4-line" />
      回收站
    </a-badge>
    <bj-modal
      title="回收站"
      :visible="visible"
      :width="1000"
      :body-style="{ height: '500px', 'max-height': '600px', position: 'relative', 'overflow-y': 'auto' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div :class="$style.modal">
        <a-row class="mb-20">
          <a-col span="4">
            <BjButton :loading="btnLoading" type="primary" @click="onClear()">全部清空</BjButton>
          </a-col>
        </a-row>
        <a-spin v-if="loading" :class="$style.spin" />
        <div v-else>
          <a-table :columns="columns" :data-source="data" :pagination="false" row-key="id">
            <template #user="item">
              <div :class="$style.box">
                <div :class="$style.cover">
                  <img :src="item.user.avatar" />
                </div>
                <span>{{ item.user.nickname }}</span>
              </div>
            </template>
          </a-table>
          <div class="pagination">
            <a-pagination
              :current="page"
              show-size-changer
              :show-total="total => `共 ${total} 条`"
              :page-size.sync="pageSize"
              :total="total"
              @showSizeChange="onShowSizeChange"
              @change="onPageChange"
            />
          </div>
        </div>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { contentCommentService } from '@/service'

const service = new contentCommentService()

export default {
  name: 'dustbin',
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      page: 1,
      pageSize: 20,
      total: 0,
      data: [],
      loading: false,
      btnLoading: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '用户',
          scopedSlots: {
            customRender: 'user',
          },
        },
        {
          title: '评论内容',
          dataIndex: 'comment',
          width: 300,
          ellipsis: true,
        },
        { title: '点赞', dataIndex: 'praise_num' },
        { title: '时间', dataIndex: 'created_at' },
        {
          title: '管理',
          customRender: item => [
            <bj-link type='primary' onClick={() => this.onRecovery(item.id)}>
              恢复
            </bj-link>,
            <a-popconfirm title='确定要彻底删除吗？删除之后就不能再次找回。' onConfirm={() => this.onDelete(item.id)}>
              <bj-link type='danger'>彻底删除</bj-link>
            </a-popconfirm>,
          ],
        },
      ]
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.getList()
      }
    },
  },
  methods: {
    onClick() {
      this.visible = true
    },
    onClear() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        content: '确定要清空全部回收站数据吗？清空之后就不能再次找回。',
        onOk() {
          _this.clear()
        },
      })
    },
    async onRecovery(id) {
      try {
        await service.recycleRecover({
          id: id,
        })
        this.$message.success('恢复成功')
      } catch (e) {
      } finally {
        this.getList()
        this.$emit('refresh')
      }
    },
    async onDelete(id) {
      try {
        await service.delete({
          id: id,
        })
        this.$message.success('删除成功')
      } catch (e) {
      } finally {
        this.getList()
        this.$emit('refresh')
      }
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async clear() {
      try {
        this.btnLoading = true
        await service.recycleClear()
        this.$message.success('清空成功')
      } catch (e) {
      } finally {
        this.btnLoading = false
        this.$emit('refresh')
        this.getList()
      }
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.recycleList({
          page: this.page,
          page_size: this.pageSize,
        })
        this.total = data.total
        this.data = data.record
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" module>
.dustbin {
  display: inline-block;
  color: #000;
  cursor: pointer;

  i {
    position: relative;
    top: 2px;
    font-size: 20px;
  }
}

.modal {
  .box {
    display: flex;
    align-items: center;
    width: 100px;
    color: @primary-color;

    span {
      margin-left: 10px;
    }
  }

  .cover {
    width: 32px;
    height: 32px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  .spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
